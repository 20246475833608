
import request from '@/utils/request'
import { UploadFilled } from '@element-plus/icons'
import { defineComponent } from 'vue'

export default defineComponent({
	components: {
		UploadFilled,
	},
	setup() {
		const validateOpRule = {
			country: [
				{
					required: true,
					validator: function (rule: any, value: any, callback: Function) {
						if (!value) {
							callback(new Error('请选择国家或地区'))
						} else {
							callback()
						}
					},
					trigger: 'change',
				},
			],
			express_id: [
				{
					required: true,
					validator: function (rule: any, value: any, callback: Function) {
						if (!value) {
							callback(new Error('请选择线路'))
						} else {
							callback()
						}
					},
					trigger: 'change',
				},
			],
		}
		return {
			validateOpRule,
		}
	},
	data() {
		return {
			packageList: [],
			expressList: [],
			disabledUpload: false,
			countryList: {} as any,
			packageForm: {
				express_id: '',
				country: 'US',
			},
		}
	},
	async created() {
		await this.getCountry()
		this.getExpress()
	},
	methods: {
		isValidate({ row }: any) {
			if (!row.validate) {
				return 'is-error-row'
			}
			return ''
		},
		async getCountry() {
			const { data } = await request
				.get(
					'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
				)
				.catch(() => {
					return {
						data: {},
					}
				})
			this.countryList = data
		},
		async uploadFile(packageForm: any, files: any) {
			this.disabledUpload = true
			const { file } = files
			const fileUpload = {
				name: file.name,
				percent: 0,
				file: file,
				url: '',
				path: '',
			}
			const formData = new FormData()
			formData.append('file', file)
			formData.append('express_id', packageForm.express_id)
			const uploader = request.post(
				'/v1/merchants/package/create/excel',
				formData,
				{
					params: {
						express_id: packageForm.express_id,
					},
					timeout: 60 * 1000,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress(e: any) {
						const percentCompleted = Math.round((e.loaded * 100) / e.total)
						fileUpload.percent = percentCompleted
					},
					timeoutErrorMessage: '网络环境较差，上传超时',
				}
			)

			const { data } = await uploader
				.catch(() => {
					throw new Error('error')
				})
				.finally(() => {
					setTimeout(() => {
						this.disabledUpload = false
					}, 1000)
				})
			this.packageList = data
		},
		async getExpress() {
			const { country } = this.packageForm
			if (!country) return
			const { data } = await request
				.get('/v1/merchants/express', {
					params: {
						country: country,
					},
				})
				.catch((e) => {
					return {
						data: [],
					}
				})
			this.expressList = data
			this.packageForm.express_id = ''
		},
	},
})
